<template>
  <div class="landing-page-banner-wrapper">
    <picture>
      <source
        :srcset="require(`@/assets/images/backgrounds/stonesLandingHero.webp`)"
        type="image/webp"
      />
      <source
        :srcset="require(`@/assets/images/backgrounds/stonesLandingHero.jpg`)"
        type="image/jpeg"
      />
      <img
        class="product-pic"
        :src="require(`@/assets/images/backgrounds/stonesLandingHero.jpg`)"
        alt="product-pic"
        width="100%"
      />
    </picture>
  </div>
</template>

<script>
export default {
  name: "hero",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.landing-page-banner-wrapper {
  font-size: 0;
}
</style>
