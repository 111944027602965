<template>
  <section class="video-section-more">
    <div class="carousel-container">
      <embla-carousel carouselName="video-carousel">
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 1</h1>
          </div>
        </div>
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 2</h1>
          </div>
        </div>
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 3</h1>
          </div>
        </div>
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 4</h1>
          </div>
        </div>
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 5</h1>
          </div>
        </div>
        <div class="embla-slide">
          <div class="background">
            <h1>Slide 6</h1>
          </div>
        </div>
      </embla-carousel>
    </div>
  </section>
</template>

<script>
import EmblaCarousel from "@/components/EmblaCarousel";
export default {
  name: "video-section-more",
  components: {
    EmblaCarousel,
  },
};
</script>

<style lang="scss" scoped>
.background {
  background-color: #000000;
  border-radius: 4em;
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  color: white;
  max-width: 1000px;
  margin: auto;
}
.video-section-more {
  background-color: #e8cf8f;
  padding: 0;
  // change to picture
}
.carousel-container {
  margin: auto;
  width: 80%;
}
@media only screen and (max-width: 768px) {
  .carousel-container {
    margin: auto;
    width: 100%;
  }
  .background {
    border-radius: 0em;
  }
}
</style>
