import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ky from "ky";
import "@/assets/global-styles.scss";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faUserSecret,
  faUserCircle,
  faQuestionCircle,
  faPhone,
  faExclamationCircle,
  faCheckCircle,
  faAngleDown,
  faCheck,
  faCaretDown,
} from "@fortawesome/free-solid-svg-icons";
import { faFacebookF, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueMeta from "vue-meta";
import VueGtag from "vue-gtag";
import AOS from "aos";
import "aos/dist/aos.css";

library.add(
  faBars,
  faFacebookF,
  faInstagram,
  faChevronLeft,
  faChevronRight,
  faUserSecret,
  faUserCircle,
  faEnvelope,
  faQuestionCircle,
  faPhone,
  faExclamationCircle,
  faCheckCircle,
  faAngleDown,
  faCheck,
  faCaretDown
);
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.config.productionTip = false;

const api = ky.create({
  prefixUrl: "https://df0lt32z9l.execute-api.ap-southeast-1.amazonaws.com/dev/",
  throwHttpErrors: false,
});

Vue.prototype.$http = api;

Vue.use(VueMeta);
Vue.use(
  VueGtag,
  {
    config: { id: "UA-176873855-1" },
  },
  router
);
new Vue({
  created() {
    AOS.init({
      offset: 10,
      duration: 1600,
      delay: 0,
    });
  },
  router,
  render: (h) => h(App),
}).$mount("#app");
