<template>
  <div id="socials-container">
    <a
      href="https://www.facebook.com/AlphaGlobalSG/?ref=settings"
      target="_blank"
      rel="noopener noreferrer"
    >
      <font-awesome-icon :icon="['fab', 'facebook-f']" size="2x" />
    </a>

    <a
      href="https://www.instagram.com/alpha_globalsg/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <font-awesome-icon :icon="['fab', 'instagram']" size="2x" />
    </a>
  </div>
</template>

<script>
export default {
  name: "social-links",
};
</script>

<style lang="scss" scoped>
#socials-container {
  display: flex;
  justify-content: center;

  a {
    color: #9c9ca8;
    padding: 30px;
  }
}
</style>
