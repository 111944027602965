<template>
  <footer>
    <div id="footer-items">
      <div id="logos">
        <picture>
          <source
            srcset="@/assets/images/branding/footerLogo.webp"
            type="image/webp"
          />
          <source
            srcset="@/assets/images/branding/footerLogo.png"
            type="image/png"
          />
          <img
            src="@/assets/images/branding/footerLogo.png"
            alt="Alpha Logo"
            width="200px"
          />
        </picture>
        <social-links />
      </div>
      <div id="pages-contact-container">
        <page-links />
        <contact-info />
      </div>
    </div>
    <dev-logo />
  </footer>
</template>

<script>
import SocialLinks from "@/components/Footer/SocialLinks";
import PageLinks from "@/components/Footer/PageLinks";
import ContactInfo from "@/components/Footer/ContactInfo";
import DevLogo from "@/components/Footer/DevLogo";
export default {
  name: "custom-footer",
  components: {
    SocialLinks,
    PageLinks,
    ContactInfo,
    DevLogo,
  },
};
</script>

<style lang="scss" scoped>
footer {
  display: flex;
  flex-direction: column;
  padding: 5rem 6rem;

  #footer-items {
    width: 100%;
    display: flex;
    padding-bottom: 1rem;

    #logos {
      width: 25%;
      min-width: 220px;
      padding: 1em;
    }

    #pages-contact-container {
      width: 60%;
      display: flex;
      justify-content: space-evenly;
    }
  }

  @media only screen and (max-width: 768px) {
    height: auto;
    padding: 1rem;
    padding-top: 10rem;
    #footer-items {
      flex-direction: column;
      align-items: center;
      padding-bottom: 1rem;
      #logos {
        width: auto;
      }

      #pages-contact-container {
        width: auto;
        flex-direction: column;
        align-items: center;
      }
    }
  }
}
</style>
