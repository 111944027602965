<template>
  <div>
    <div id="contact-us-section-container">
      <div id="contact-us-inner-container">
        <div id="contact-us-title">
          <h2>For Enquiries</h2>
        </div>
        <div id="contact-us-button-wrapper">
          <a href="/contact">
            <button class="button" id="contact-us-button">Contact Us</button>
          </a>
          <!-- <button class="button" id="contact-us-button" @click="onModalChange">
            Contact Us
          </button> -->
        </div>
      </div>
    </div>
    <modal-email :active.sync="showModal" @close="onModalChange" />
  </div>
</template>
<script>
import ModalEmail from "../ModalEmail.vue";
export default {
  name: "email-section",
  components: {
    ModalEmail,
  },
  data() {
    return {
      email: null,
      showModal: false,
    };
  },
  methods: {
    addClassToLabel(e) {
      if (e.type === "focus") {
        document
          .querySelector(`label[for="${e.target.name}"]`)
          .classList.add("active");
      } else {
        if (e.target.value !== "") {
          //If it is not an empty String
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.add("active");
        } else {
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.remove("active");
        }
      }
    },
    onModalChange() {
      this.showModal = !this.showModal;
      document.body.classList.toggle("modal-open");
      if (this.showModal) {
        document.getElementById("email-modal").focus();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-us-section-container {
  width: 30%;
  min-width: 400px;
  margin: auto;
  height: auto;
  background-color: #107e79;
  border-radius: 100px;
  margin-top: -55px;
}
#contact-us-inner-container {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  margin: auto;
  padding: 20px 10px;
  justify-content: space-between;
}

#contact-us-title {
  width: 35%;
  margin: auto;
  text-align: left;
  padding-left: 10px;
  h2,
  p {
    color: white;
  }
  h2 {
    font-weight: 500;
  }
}

#contact-us-button-wrapper {
  margin: auto;
  display: flex;
  position: relative;
  justify-content: center;
}

#contact-us-button {
  width: 100%;
  border: 2px solid #107e79;
  padding: 1.1rem 2rem;
  border-radius: 25px;
  transition: 0.3s all ease-in-out;
  &:hover {
    border-color: #f4f4f0;
    color: #f4f4f0;
  }
}

@media (max-width: 550px) {
  #contact-us-section-container {
    margin-top: -100px;
    width: 80%;
    min-width: 100px;
    max-width: 300px;
  }
  #contact-us-inner-container {
    display: block;
    padding: 20px 0px;
  }
  #contact-us-title {
    width: 80%;
    margin: auto;
    text-align: center;
    padding-left: 0px;
  }
  #contact-us-button-wrapper {
    width: 50%;
  }
}
</style>
