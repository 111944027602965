import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import Meta from "vue-meta";

Vue.use(Meta);
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/blog",
    name: "Blog",
    component: () => import(/* webpackChunkName: "blog" */ "../views/Blog.vue"),
  },
  {
    path: "/testimonial",
    name: "Testimonial",
    component: () =>
      import(/* webpackChunkName: "testimonials" */ "../views/Testimonial.vue"),
  },
  {
    path: "/product",
    name: "Product",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Product.vue"),
  },
  {
    path: "/therapy",
    name: "Therapy",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Therapy.vue"),
  },
  {
    path: "/contact",
    name: "Contact Us",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/ContactUs.vue"),
  },
  {
    path: "/product/:productId",
    name: "ProductDetail",
    component: () => import("../views/ProductDetail.vue"),
  },
  {
    path: "/workshop",
    name: "Workshop",
    component: () => import("../views/Workshop.vue"),
  },
  {
    path: "/unsubscribe",
    name: "Unsubscribe",
    component: () => import("../views/Unsubscribe.vue"),
  },
  {
    path: "/confirmation/:workshopId/:email",
    name: "Confirmation",
    component: () => import("../views/ConfirmWorkshop.vue"),
  },
  {
    path: "/policy",
    name: "PrivacyPolicy",
    component: () => import("../views/PrivacyPolicy.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
