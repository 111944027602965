<template>
  <div>
    <hero />
    <video-section />
    <products-section />
    <!-- <testimonial-section /> -->
    <email-section />
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/LandingPage/Hero.vue";
import VideoSection from "@/components/LandingPage/VideoSection.vue";
import ProductsSection from "@/components/LandingPage/ProductsSection.vue";
// import TestimonialSection from "@/components/LandingPage/TestimonialSection.vue";
import EmailSection from "@/components/LandingPage/EmailSection.vue";

export default {
  name: "Home",
  components: {
    Hero,
    VideoSection,
    ProductsSection,
    // TestimonialSection,
    EmailSection,
  },
  metaInfo: {
    meta: [
      {
        name: "description",
        content:
          "Alpha Global Wellness Singapore, seeking the most advanced techonology to bring out the best of nature's energy and natural healing methods.",
      },
    ],
  },
};
</script>
