<template>
  <div id="contact-form-container">
    <form @submit.prevent="onSubmit">
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'user-circle']" size="2x" />
        <div class="field">
          <input
            v-model="name"
            type="text"
            name="name"
            autocomplete="name"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="name">Name</label>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['far', 'envelope']" size="2x" />
        <div class="field">
          <input
            v-model="email"
            type="email"
            name="email"
            autocomplete="email"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="email">Email</label>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'question-circle']" size="2x" />
        <div class="field">
          <select name="type-of-query" v-model="type">
            <option value="general">General Enquiry</option>
            <option value="purchase">Purchase Enquiry</option>
            <option value="maintenance">Product Maintenance & Warranty</option>
          </select>
        </div>
      </div>
      <div class="input-container">
        <font-awesome-icon :icon="['fas', 'phone']" size="2x" />
        <div class="field">
          <input
            v-model="phone"
            type="tel"
            name="telephone"
            autocomplete="tel"
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          />
          <label for="telephone">Phone No.</label>
        </div>
      </div>
      <div class="input-container">
        <div class="field">
          <textarea
            v-model="msg"
            name="message"
            cols="30"
            rows="10"
            required
            @focus="addClassToLabel"
            @blur="addClassToLabel"
          ></textarea>
          <label for="message">Your message</label>
        </div>
      </div>
      <span>
        By submitting you agree with the
        <b>
          <router-link to="/Policy" target="_blank" rel="noopener noreferrer">
            Terms And Conditions
          </router-link>
        </b>
      </span>
      <div id="email-checkbox">
        <input
          type="checkbox"
          name="receive"
          id="receive"
          v-model="newsletter"
        />
        <label class="custom-checkbox font-size-one" for="receive">
          Keep me updated on future products/workshops.
        </label>
      </div>
      <div id="contact-submission-btn">
        <button type="submit">
          <span v-if="!submitting">Submit</span>
          <span v-else>Loading...</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: "contact-form",
  data() {
    return {
      name: null,
      email: null,
      type: null,
      phone: null,
      msg: null,
      newsletter: false,
      submitting: false,
      success: false,
      error: false,
    };
  },
  methods: {
    openPdf() {},
    async onSubmit() {
      this.submitting = true;
      const res = await this.$http
        .post("contact/submit", {
          json: {
            name: this.name,
            email: this.email,
            "type-of-query": this.type,
            telephone: this.phone,
            message: this.msg,
            newsletter: this.newsletter,
          },
        })
        .json();
      this.$emit("submit-result", res);
      this.submitting = false;
    },
    addClassToLabel(e) {
      if (e.type === "focus") {
        document
          .querySelector(`label[for="${e.target.name}"]`)
          .classList.add("active");
      } else {
        if (e.target.value !== "") {
          //If it is not an empty String
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.add("active");
        } else {
          document
            .querySelector(`label[for="${e.target.name}"]`)
            .classList.remove("active");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#contact-form-container {
  text-align: left;
  form {
    padding: 4rem;
    padding-top: 0.83em;
    @media screen and (max-width: 768px) {
      padding: 0;
    }
  }

  .input-container {
    display: flex;
    margin: 2rem 0;
    background-color: #f4f4f0;
    padding: 1.5rem 1rem 0.8rem 1rem;
    border-radius: 20px;
    svg {
      padding: 10px 10px 10px 5px;
      color: #cccccc;
    }
  }

  select {
    width: 100%;
    border: none;
    outline: none;
    background-color: #f4f4f0;
    &:focus {
      outline: #cccccc solid 1px;
    }
  }

  #contact-submission-btn {
    font-size: 1.8rem;
    text-align: center;
    button {
      font-size: 1.3rem;
      cursor: pointer;
      outline: none;
      border: none;
      background-color: #107e79;
      color: #f4f4f0;
      font-weight: bold;
      width: 80%;
      padding: 1.5rem;
      border-radius: 20px;
      transition: background-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;

      &:hover {
        background-color: #0f706b;
      }
    }
  }
}

#email-checkbox {
  display: block;
  width: 100%;
  padding: 1rem 0.2rem;

  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }

  label {
    font-size: 1.2rem;
    position: relative;
    cursor: pointer;

    &::before {
      content: "";
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid #cccccc;
      background-color: #f4f4f0;
      border-radius: 7px;
      padding: 1rem;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      margin-right: 1rem;
      transition: background-color 0.3s ease;
      -webkit-transition: background-color 0.3s ease;
      -moz-transition: background-color 0.3s ease;
      -ms-transition: background-color 0.3s ease;
      -o-transition: background-color 0.3s ease;
    }
  }

  input:checked {
    + label::after {
      content: "";
      display: block;
      position: absolute;
      top: -3px;
      left: 10px;
      width: 12px;
      height: 20px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
    + label::before {
      background-color: #107e79;
      border: 1px solid #107e79;
    }
  }
}

.field {
  position: relative;
  display: flex;
  width: 100%;
  margin-left: 0.5rem;
  textarea {
    resize: none;
  }

  input,
  textarea {
    background: none;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    &:focus {
      outline: none;
    }
    // &:focus ~ label,
    // &:optional ~ label,
    // &:valid ~ label {
    //   top: -14px;
    //   font-size: 0.8rem;
    // }
  }

  label {
    color: #9c9ca8;
    font-size: 1rem;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 8px;
    top: 15px;
    transition: 300ms ease all;
    &.active {
      top: -14px;
      font-size: 0.8rem;
    }
  }
}

.font-size-one {
  font-size: 1rem !important ;
}
// .rotate {
//   -moz-transition: all 10s linear;
//   -webkit-transition: all 10s linear;
//   transition: all 10s linear;
// }
// .rotate.down {
//   -ms-transform: rotate(3600deg);
//   -moz-transform: rotate(3600deg);
//   -webkit-transform: rotate(3600deg);
//   transform: rotate(3600deg);
// }
</style>
