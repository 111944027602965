<template>
  <div class="brown">
    <section
      class="video-section"
      :style="{ backgroundImage: `url(${backgroundUrl})` }"
    >
      <div class="video-content-container">
        <div class="desc">
          <p class="title">About Alpha Global Wellness</p>
          <p class="content">
            Only when our body system is functioning harmoniously within us, we
            are able to enjoy greater happiness and experience what quality
            living is all about!
            <br />
            With the passion to achieve balance and harmony much needed by our
            body system, we are constantly seeking the most advanced technology
            to bring out the best of nature's energy and healing methods
            <br /><br />
            As the co-founder of Detox@Balance & Cleanse(HQ Centre), we are
            delighted to bring to you the Quantum Cellular Detox therapy as part
            of a progressive and preventive health program to enable our body to
            Regenerate, Renew and Self-Heal!
          </p>
          <div
            class="desc-background"
            :style="{ backgroundImage: `url(${backgroundUrl})` }"
          ></div>
        </div>
      </div>
    </section>
    <transition name="fade">
      <video-section-more class="more" v-if="!hide" />
    </transition>
  </div>
</template>

<script>
import backgroundUrl from "@/assets/images/backgrounds/qi.png";
import VideoSectionMore from "@/components/LandingPage/VideoSectionMore";
export default {
  name: "video-section",
  components: {
    VideoSectionMore,
  },
  data() {
    return {
      HolographicImgSrc: "HHLandingPage",
      backgroundUrl,
      text: "Find out more",
      hide: true,
    };
  },
  methods: {
    toggleHide() {
      if (this.hide) {
        this.text = "Show Less";
      } else {
        this.text = "Find out more";
      }
      this.hide = !this.hide;
    },
  },
};
</script>

<style lang="scss" scoped>
.video-content-container {
  padding-top: 150px;
  padding-bottom: 150px;
}
.brown {
  background-color: #e8cf8f;
}
.video-section div {
  // vertical-align: middle;
}

.video-section {
  -webkit-transition: max-height 1s;
  transition: height 0.4s linear;
  background-color: #e8cf8f;
  background-size: auto 95%;
  background-repeat: no-repeat;
  background-position: right;
  padding: 0;
}
.desc-background {
  margin: auto;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  opacity: 0;
  z-index: -1;
}
.desc {
  position: relative;
  width: 100%;
  margin: 0;
  background-size: auto 90%;
  background-repeat: no-repeat;
  background-position: center;
}
.title {
  font-weight: bold;
  font-size: 2em;
}
.content {
  margin: auto;
  width: 70%;
  padding-top: 20px;
  font-size: 1.3em;
  line-height: 1.2;
}

@media only screen and (max-width: 1460px) {
  .content {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 1024px) {
  .desc-background {
    opacity: 0.7;
    z-index: 0;
  }
  .content {
    padding-left: 2em;
    padding-right: 2em;
  }
  .video-section {
    background-size: auto 50%;
    background-position: top;
    padding: 0;
    background-image: none !important;
  }
  .video-image {
    width: 80vw;
    padding-bottom: 50px;
    margin: 0;
  }
  .desc {
    width: 95%;
    padding-left: 0;
    margin: auto;
    margin-top: 2em;
  }
  .title {
    padding-top: 0;
    font-size: 1.5em;
  }
  .video-section {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: start;
    -moz-box-align: start;
    -ms-flex-align: start;
    -webkit-align-items: flex-start;
    align-items: flex-start;
  }
  .video-section .video {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    -webkit-order: 2;
    order: 2;
    margin: auto;
    height: 60%;
  }
  .video-image {
    padding-top: 0;
    padding-bottom: 0;
    width: 90vw;
  }

  .video-section .desc {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    -webkit-order: 1;
    order: 1;
  }
  .video-section .button-container {
    margin: auto;
    padding: 2em;
    -webkit-box-ordinal-group: 3;
    -moz-box-ordinal-group: 3;
    -ms-flex-order: 3;
    -webkit-order: 3;
    order: 3;
    width: 80%;
  }
  .video-content-container {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 450px) {
  iframe {
    width: 100vw !important;
  }
  .video-section {
    padding: 0;
  }
  .content {
    font-size: 1em;
  }
}
</style>
