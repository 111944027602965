<template>
  <section class="products-section">
    <span class="title">Products</span>
    <hyper-link title="see all" link="/Product" />

    <div class="row product1-container">
      <div class="image-container shadow-left">
        <picture id="image1" class="">
          <source
            :srcset="
              require(`@/assets/images/products/HH/${HolographicImgSrc}.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/products/HH/${HolographicImgSrc}.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="product-pic"
            :src="
              require(`@/assets/images/products/HH/${HolographicImgSrc}.jpg`)
            "
            alt="product-pic"
          />
        </picture>
      </div>
      <div class="product-detail left">
        <span class="p1-title p-title">Holographic Harmoniser</span>
        <br />
        <span class="p1-desc p-desc">
          Designed to protect you from the side-effects of Electromagnetic
          Waves(EMW)
          <br />
        </span>
        <br />
        <router-link class="button" to="/product/1">
          Find out more
        </router-link>
      </div>
    </div>

    <div class="row product2-container">
      <div class="product-detail right desktop-view">
        <span class="p2-title p-title">NU Hydrogen Water Bottle</span>
        <br />
        <span class="p2-desc p-desc">
          Hydrogen Water rich in antioxidant against free radicals
        </span>
        <br />
        <router-link class="button" to="/product/2">
          Find out more
        </router-link>
      </div>
      <div class="small image-container shadow-right">
        <picture>
          <source
            :srcset="
              require(`@/assets/images/products/NHWB/${NUHydrogenImgSrc}.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/products/NHWB/${NUHydrogenImgSrc}.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="portrait product-pic"
            :src="
              require(`@/assets/images/products/NHWB/${NUHydrogenImgSrc}.jpg`)
            "
            alt="product-pic"
          />
        </picture>
      </div>
      <div class="mobile-view" style="display: none">
        <div class="product-detail right">
          <span class="p2-title p-title">NU Hydrogen Water Bottle</span>
          <br />
          <span class="p2-desc p-desc">
            Hydrogen water rich in antioxidants against free radicals
          </span>
          <br />
          <router-link class="button" to="/product/2">
            Find out more
          </router-link>
        </div>
      </div>
    </div>

    <div class="row product3-container">
      <div class="image-container shadow-left">
        <picture id="image3" class="picture-warpper-product">
          <source
            :srcset="
              require(`@/assets/images/products/AMWET/${AlphaMultiImgSrc}.webp`)
            "
            type="image/webp"
          />
          <source
            :srcset="
              require(`@/assets/images/products/AMWET/${AlphaMultiImgSrc}.jpg`)
            "
            type="image/jpeg"
          />
          <img
            class="product-pic"
            :src="
              require(`@/assets/images/products/AMWET/${AlphaMultiImgSrc}.jpg`)
            "
            alt="product-pic"
          />
        </picture>
      </div>
      <div class="product-detail left">
        <span class="p3-title p-title">
          Alpha Multi-Wave Energy Therapy (MWET) Device
        </span>
        <br />
        <span class="p3-desc p-desc">
          Unique combination of energy waves to stimulate bioenergy(Qi) and
          blood circulation
        </span>
        <br />
        <router-link class="button" to="/product/3">
          Find out more
        </router-link>
      </div>
    </div>
  </section>
</template>

<script>
import HyperLink from "@/components/HyperLink";
export default {
  name: "products-section",
  components: {
    HyperLink,
  },
  data() {
    return {
      HolographicImgSrc: "HHLandingPage",
      NUHydrogenImgSrc: "NHWBLandingPage",
      AlphaMultiImgSrc: "AMWETLandingPage",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.shadow-left {
  box-shadow: -30px 30px #107e79;
}
.shadow-right {
  box-shadow: 30px 30px #107e79;
}
.product3-container {
  // height: 60vh !important;
  padding-bottom: 8em;
}
ButtonNormal {
  font-size: 2em !important;
}
.picture-warpper-product {
  height: 0px;
}
.portrait {
  width: auto !important;
}
.row {
  padding-top: 5%;
  // height: 50vh;
}
.left {
  text-align: left;
  padding-left: 5vw;
}
.right {
  text-align: right;
  padding-right: 5vw;
}
.product-detail {
  width: 35vw;
  position: relative;
}
.image-container {
  // width: 40vw;
  position: relative;
  height: 100%;
  background-color: #e4d8cf;
  max-width: 35vw;
  border-radius: 15px;
}
.product-pic {
  width: auto;
  border-radius: 15px;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
  display: block;
}
.small {
  width: 300px !important;
  max-height: 450px;
}
.row div {
  display: inline-block;
  vertical-align: middle;
}
.title {
  font-weight: 500;
  padding-top: 1em;
  padding-bottom: 0.3em;
  font-size: 3em;
  display: block;
}
.products-section {
  // min-height: 100vh;
  height: auto;
  background-color: #aec99c;
}
.p-title {
  padding-top: 3em;
  font-size: 2.8em;
  display: block;
}
.p-desc {
  font-size: 1.5em;
  width: 85%;
  display: block;
  padding-bottom: 3px;
}
.p2-desc {
  margin-left: auto;
}
.p3-title {
  font-size: 2.3em;
}
@media only screen and (max-width: 1024px) {
  .shadow-left {
    box-shadow: -15px 15px #107e79;
  }
  .shadow-right {
    box-shadow: 15px 15px #107e79;
  }
  .image-container {
    height: auto;
    max-width: none;
  }
  .small {
    width: 250px !important;
  }
  .row {
    height: auto !important;
  }
  .desktop-view {
    display: none !important;
  }
  .mobile-view {
    display: block !important;
  }
  .product-pic {
    display: block;
    position: static;
  }
  .row div {
    display: block;
  }
  .product-detail {
    width: 100%;
    text-align: center;
  }
  .p-title,
  .p-desc {
    width: 100%;
  }
  .p-title {
    padding-top: 1.5em;
  }
  .product-pic {
    width: 100%;
  }
  .image-container {
    margin: auto;
    padding: 0 !important;
    width: 80%;
  }
  .left,
  .right {
    padding: 0;
  }
}
@media only screen and (max-width: 450px) {
  .title {
    font-size: 2.5em;
  }
  .p-title {
    font-size: 2em;
  }
  .p-desc {
    font-size: 1.2em;
  }
  .row {
    padding-top: 15%;
  }
}
</style>
