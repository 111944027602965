<template>
  <div
    tabindex="-1"
    id="email-modal"
    class="modal"
    :class="{ 'is-active': active }"
  >
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-container">
      <div class="modal-wrapper">
        <div class="form-wrapper">
          <h2>Contact Us</h2>
          <contact-form
            @submit-result="onSubmissionResult"
            v-if="!submissionReceived"
          />
          <div id="submission-result-wrapper" v-else>
            <div id="success-submission" v-if="submissionResult">
              <font-awesome-icon :icon="['fas', 'check-circle']" size="2x" />
              <p>Success</p>
            </div>
            <div id="fail-submission" v-else>
              <font-awesome-icon
                :icon="['fas', 'exclamation-circle']"
                size="2x"
              />
              <p>An <b>error</b> occured! Please try again!</p>
            </div>
          </div>
        </div>
      </div>
      <button class="button" @click="$emit('close')">
        <b>X</b>
      </button>
    </div>
  </div>
</template>
<script>
import ContactForm from "./ContactPage/ContactForm.vue";
export default {
  name: "modal-email",
  components: {
    ContactForm,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data() {
    return {
      submissionReceived: false,
      submissionResult: false,
      isComponentModalActive: false,
    };
  },
  mounted() {
    document.onkeydown = this.keyPressed;
  },
  methods: {
    keyPressed(ev) {
      if (ev.keyCode === 27 && this.active) {
        this.$emit("close");
      }
    },
    onSubmissionResult(res) {
      this.submissionReceived = true;
      this.submissionResult = false;
      if (!res.error) {
        this.submissionResult = true;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.modal {
  align-items: center;
  display: none;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  z-index: 40;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;

  &.is-active {
    display: flex;
  }
}

.modal-background {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  background-color: hsla(0, 0%, 4%, 0.86);
}
.modal-container {
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: center;
  margin: 1rem;
  height: 700px;
  z-index: 1;
  @media only screen and (max-width: 1080px) {
    height: 450px;
  }
}
.form-wrapper {
  overflow: auto;
  height: 100%;
  padding: 0 20px 0 20px;
}
.modal-wrapper {
  background-color: #fff;
  border-radius: 25px;
  overflow: auto;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  padding: 0.5rem;
  height: 100%;
}
#submission-result-wrapper {
  min-width: 200px;
  margin-top: 250px;
  @media screen and (max-width: 1080px) {
    margin-top: 100px;
  }
}
button {
  position: fixed;
  right: 20px;
  top: 20px;
  background-color: white;
  border: none;
  color: black;
  padding: 20px;
  text-decoration: none;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 5px;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  b {
    font-size: 20px;
  }
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 25px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 25px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
