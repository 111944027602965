<template>
  <address id="contact-info">
    <h3>Contact Us</h3>
    <p>+65 9329 3737</p>
    <a
      href="mailto:hello@alphaglobalwellness.sg"
      target="_blank"
      rel="noopener noreferrer"
    >
      enquiries@alpgw.com
    </a>
    <a href="https://www.alpgw.com/">www.alpgw.com</a>
    <p>
      <!-- 100 Pasir Panjang #02-08<br />
      100 Pasir Panjang Road Singapore 118518. -->
    </p>
  </address>
</template>

<script>
export default {
  name: "contact-info",
};
</script>

<style lang="scss" scoped>
#contact-info {
  padding: 20px;
  text-align: left;
  font-style: normal;
  p {
    margin-top: 1rem;
    margin-bottom: 0;
    color: #8a8a8a;
  }

  a {
    color: #8a8a8a;
    margin-top: 1rem;
    display: block;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}
</style>
