<template>
  <main id="app">
    <!-- <nav-bar-mobile id="navMobileBarId" v-if="mobileView" /> -->
    <nav-bar />
    <router-view />
    <custom-footer />
  </main>
</template>

<script>
import NavBar from "./components/NavBar";
import CustomFooter from "@/components/Footer/CustomFooter";
export default {
  name: "App",
  components: {
    NavBar,
    CustomFooter,
  },
  metaInfo: {
    title: "ALPGW",
    titleTemplate:
      "%s | Alpha Global Wellness | Natural Healing through modern technology",
  },

  watch: {
    $route() {
      const navEl = document.getElementById("sidebar");
      const toggleBtnEl = document.getElementById("toggle-btn");
      if (navEl && toggleBtnEl) {
        if (navEl.classList.contains("active"))
          navEl.classList.remove("active");
        if (toggleBtnEl.classList.contains("active"))
          toggleBtnEl.classList.remove("active");
      }
    },
  },
};

// Work Sans on google fonts for font just a note
</script>

<style lang="scss">
#app {
  width: 100%;
  font-family: Book Antiqua, Palatino, Palatino Linotype, Palatino LT STD,
    Georgia, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  // color: #2c3e50;
  overflow-x: hidden;
}
</style>
