<template>
  <nav>
    <div id="nav" class="nav-wrapper" v-if="!mobileView">
      <router-link to="/" exact>
        <picture>
          <source
            :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.webp`)"
            type="image/webp"
          />
          <source
            :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.png`)"
            type="image/png"
          />
          <img
            class="nav-logo"
            :src="require(`@/assets/images/branding/AlphaLogo-navbar.png`)"
            alt="product-pic"
            width="300px"
          /> </picture
      ></router-link>
      <div id="nav-links-container">
        <router-link to="/" exact class="nav-links">Home</router-link>
        <router-link to="/product" class="nav-links">Product</router-link>
        <div class="dropdown-button">
          <router-link to="/therapy" class="nav-links"
            >Detox Therapy</router-link
          ><font-awesome-icon :icon="['fa', 'caret-down']" />
          <div class="dropdown-content">
            <ul>
              <li>
                <router-link to="/therapy#about-detox"
                  >About Quantum Cellular Detox</router-link
                >
              </li>
              <li>
                <router-link to="/therapy#bionizer"
                  >Quantum Detox Bionizer</router-link
                >
              </li>
              <li>
                <router-link to="/therapy#howDoesitWork"
                  >How Does It Work</router-link
                >
              </li>
              <li>
                <router-link to="/therapy#benefits"
                  >Progressive Health Benefits</router-link
                >
              </li>
              <li>
                <router-link to="/therapy#faq">FAQ</router-link>
              </li>
            </ul>
          </div>
        </div>

        <!-- <router-link to="/testimonial">Testimonial</router-link> -->
        <router-link to="/about" class="nav-links">About Us</router-link>
        <router-link to="/contact" class="nav-links">Contact Us</router-link>
        <router-link to="/workshop" class="nav-links">Workshop</router-link>
      </div>
    </div>

    <div id="mobile-nav" class="nav-wrapper" v-else>
      <router-link to="/" exact>
        <picture id="mobile-nav-logo">
          <source
            :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.webp`)"
            type="image/webp"
          />
          <source
            :srcset="require(`@/assets/images/branding/AlphaLogo-navbar.png`)"
            type="image/png"
          />
          <img
            class="nav-logo"
            :src="require(`@/assets/images/branding/AlphaLogo-navbar.png`)"
            alt="product-pic"
            width="200px"
          /> </picture
      ></router-link>

      <div id="toggle-btn" @click="toggleSideBar()">
        <div class="btn-burger"></div>
      </div>
    </div>
    <div id="sidebar" v-if="mobileView">
      <ul>
        <router-link tag="li" to="/">Home</router-link>
        <router-link tag="li" to="/product">Product</router-link>
        <router-link tag="li" to="/therapy">Detox Therapy</router-link>
        <!-- <router-link tag="li" to="/testimonial">Testimonial</router-link> -->
        <router-link tag="li" to="/about">About Us</router-link>
        <router-link tag="li" to="/contact">Contact Us</router-link>
        <router-link tag="li" to="/workshop">Workshop</router-link>
      </ul>
    </div>
  </nav>
</template>
<script>
export default {
  name: "navbar",
  components: {
    // "nav-bar-mobile": NavBarMobile
  },
  data: () => {
    return {
      windowWidth: window.innerWidth,
      prevScrollHeight: null,
      sidebarActive: false,
    };
  },

  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    toggleSideBar() {
      if (this.mobileView) {
        document.getElementById("sidebar").classList.toggle("active");
        document.getElementById("toggle-btn").classList.toggle("active");
        document.getElementById("mobile-nav-logo").classList.toggle("active");
        this.sidebarActive = !this.sidebarActive;
      }
    },
    handleScroll() {
      if (!this.sidebarActive) {
        let scrollHeightNow = window.scrollY;
        if (scrollHeightNow > 0) {
          if (this.prevScrollHeight > window.scrollY) {
            this.setNavStyle("0");
          } else {
            this.setNavStyle("-300px");
          }
          this.prevScrollHeight = scrollHeightNow;
        }
      }
    },
    setNavStyle(heightVal) {
      if (this.mobileView) {
        document.getElementById("mobile-nav").style.top = heightVal;
      } else {
        document.getElementById("nav").style.top = heightVal;
      }
    },
  },

  computed: {
    mobileView() {
      return this.windowWidth <= 1190;
    },
  },
  watch: {
    $route: function () {
      if (this.mobileView) {
        var navBarLogo = document.getElementById("mobile-nav-logo");
        if (navBarLogo.classList) {
          if (navBarLogo.classList.contains("active")) {
            navBarLogo.classList.remove("active");
          }
        }
      }
      this.setNavStyle("0");
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
    window.addEventListener("scroll", this.handleScroll);
  },

  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.onResize);
  },
};
</script>
<style lang="scss" scoped>
#dropdown {
  position: absolute;
}
.nav-wrapper {
  position: fixed;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;
  -webkit-transition: 1s;
  -moz-transition: 1s;
  -ms-transition: 1s;
  -o-transition: 1s;
  transition: 1s;
  height: 100px;
  width: 100%;
}
.nav-links {
  position: relative;
  display: inline-block;
  color: white;
  text-decoration: none;
  margin: 2em;
  padding-bottom: 3px;
  font-weight: lighter;
  transition: 300ms all ease-out;

  &::after {
    display: block;
    content: "";
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 300ms ease-in-out;
  }
  &.router-link-active,
  &:hover {
    text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;

    &::after {
      transform: scaleX(1);
    }
  }
}
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #6868686c;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  min-width: 300px;
  z-index: 1;
  margin-top: 5px;
  a {
    float: none;
    text-decoration: none;
    display: block;
    text-align: left;
    color: white;
    &:hover {
      text-shadow: 0 0 0.9px white, 0 0 0.9px white, 0 0 0.9px white;

      &::after {
        transform: scaleX(1);
      }
    }
  }
  ul {
    list-style: none;
    padding: 0px;
  }
}
.dropdown-button {
  a {
    margin: 2em 10px 0 2em !important;
  }
  svg {
    color: rgb(235, 235, 235);
  }
}
.dropdown-button:hover .dropdown-content {
  display: block;
}
#nav-links-container {
  padding-right: 1rem;
  display: flex;
}
.nav-logo {
  font-family: "Unica One";
  font-size: 1.3rem;
  color: white;
  padding-left: 3rem;
}

#mobile-nav-logo {
  -webkit-transition: opacity 0.3s ease-in-out, transform 1s;
  -moz-transition: opacity 0.3s ease-in-out, transform 1s;
  -ms-transition: opacity 0.3s ease-in-out, transform 1s;
  -o-transition: opacity 0.3s ease-in-out, transform 1s;
  transition: opacity 0.3s ease-in-out, transform 1s;
  opacity: 1;
  &.active {
    opacity: 0;
  }
}
#mobile-nav {
  z-index: 3;
}
#toggle-btn {
  height: 7vh;
  width: 7vh;
  min-height: 30px;
  min-width: 30px;
  padding: 2vh 4vw;
  margin-left: auto;
  cursor: pointer;
}
#sidebar {
  right: -1190px;
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  z-index: 2;
  cursor: pointer;
  transition: all 300ms linear;
  padding-top: 10vh;

  &.active {
    right: 0px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      padding: 15px 10px;
      width: 50%;
      margin: auto;
      color: white;
      text-decoration: none;
      &.router-link-exact-active {
        color: #ffffff !important;
        border-bottom: 1px solid white;
        padding-bottom: 0.5rem;
      }
    }
  }
}

#toggle-btn {
  height: 25px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    .btn-burger {
      transform: rotate(135deg);
    }
    .btn-burger::before,
    .btn-burger::after {
      top: 0;
      transform: rotate(90deg);
    }
  }
}

.btn-burger {
  position: relative;
  max-width: 30px;
  max-height: 5px;
  min-width: 25px;
  min-height: 3px;
  width: 7vh;
  height: 2vh;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  transition: all 0.7s ease;

  &::before,
  &::after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -10px;
    max-width: inherit;
    max-height: inherit;
    min-width: inherit;
    min-height: inherit;
    width: inherit;
    height: inherit;
    border-radius: inherit;
    background: inherit;
  }
  &::after {
    top: 10px;
  }
}

@media only screen and (max-width: 1024px) {
  .headerTitle {
    font-size: 1.5rem;
  }
  .nav-logo {
    padding-left: 4vw;
  }
  .nav-wrapper {
    height: 85px;
    width: 100%;
  }
}

@media only screen and (max-width: 450px) {
  .headerTitle {
    font-size: 1rem;
  }
}
</style>
