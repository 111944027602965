<template>
  <div id="links">
    <h3>Links</h3>
    <router-link to="/">Home</router-link>
    <router-link to="/product">Product</router-link>
    <router-link to="/about">About Us</router-link>
    <router-link to="/workshop">Workshop</router-link>
    <router-link to="/policy">Privacy Policy</router-link>
  </div>
</template>

<script>
export default {
  name: "page-links",
};
</script>

<style lang="scss" scoped>
#links {
  padding: 20px;
  text-align: left;
  a {
    color: #8a8a8a;
    margin-top: 1rem;
    display: block;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }

  @media only screen and (max-width: 768px) {
    text-align: center;
  }
}
</style>
