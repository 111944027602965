<template>
  <div id="dev-logo-container">
    <div>
      <p>Another Website By</p>
      <a href="https://webbrickssg.com/">
        <picture>
          <source
            srcset="@/assets/images/branding/webBricks-logo-small.webp"
            type="image/webp"
          />
          <source
            srcset="@/assets/images/branding/webBricks-logo-small.png"
            type="image/png"
          />
          <img
            src="@/assets/images/branding/webBricks-logo-small.png"
            alt="WebBricks Logo"
            width="184px"
          />
        </picture>
      </a>
    </div>
    <p class="footer-disclaimer">
      Disclaimer: All products presented by Alpha Global Wellness are developed
      by its respective manufacturer and research company who will also be
      taking care of the warranty and repair matters. The products featured were
      designed to enhance the general wellbeing and maintenance of better
      health, it is not meant to replace any medical treatment. Customers are
      advised to seek proper medical treatment for any health issues.
    </p>
  </div>
</template>

<script>
export default {
  name: "dev-logo",
};
</script>

<style lang="scss" scoped>
#dev-logo-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  div {
    width: 25%;
  }

  .footer-disclaimer {
    display: block;
    width: 65%;
    text-align: left;
    color: #8a8a8a;
  }

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    div {
      width: 100%;
    }
    .footer-disclaimer {
      width: 100%;
      padding: 50px 0;
      text-align: center;
    }
  }
}
</style>
