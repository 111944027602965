<template>
  <router-link :to="link" class="hyper-link">
    <span>{{ title }}</span>
  </router-link>
</template>

<script>
export default {
  props: {
    title: String,
    link: String,
  },
};
</script>

<style lang="scss" scoped>
.hyper-link {
  color: #107e79;
  font-size: 1.7em;
  text-decoration: underline;
  font-style: italic;
}
.hyper-link:hover {
  text-decoration: none;
}
@media (max-width: 700px) {
  .hyper-link {
    font-size: 1.4em;
  }
}
@media only screen and (max-width: 450px) {
  .hyper-link {
    font-size: 1.7em;
  }
}
</style>
