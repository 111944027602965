<template>
  <div class="embla" :name="carouselName">
    <div class="embla-viewport">
      <div class="embla-container">
        <slot></slot>
      </div>
    </div>

    <!-- Buttons -->
    <button class="embla-button prev" type="button">
      <svg viewBox="137.718 -1.001 366.563 643.999">
        <path
          d="M428.36 12.5c16.67-16.67 43.76-16.67 60.42 0 16.67 16.67 16.67 43.76 0 60.42L241.7 320c148.25 148.24 230.61 230.6 247.08 247.08 16.67 16.66 16.67 43.75 0 60.42-16.67 16.66-43.76 16.67-60.42 0-27.72-27.71-249.45-249.37-277.16-277.08a42.308 42.308 0 0 1-12.48-30.34c0-11.1 4.1-22.05 12.48-30.42C206.63 234.23 400.64 40.21 428.36 12.5z"
        ></path>
      </svg>
    </button>
    <button class="embla-button next" type="button">
      <svg viewBox="0 0 238.003 238.003">
        <path
          d="M181.776 107.719L78.705 4.648c-6.198-6.198-16.273-6.198-22.47 0s-6.198 16.273 0 22.47l91.883 91.883-91.883 91.883c-6.198 6.198-6.198 16.273 0 22.47s16.273 6.198 22.47 0l103.071-103.039a15.741 15.741 0 0 0 4.64-11.283c0-4.13-1.526-8.199-4.64-11.313z"
        ></path>
      </svg>
    </button>
  </div>
</template>

<script>
import EmblaCarousel from "embla-carousel";
export default {
  props: {
    carouselName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      emblaInstance: null,
    };
  },
  mounted() {
    const wrap = document.getElementsByName(this.carouselName)[0];
    const emblaNode = wrap.querySelector(".embla-viewport");
    this.emblaInstance = EmblaCarousel(emblaNode, { loop: true });

    //Setup buttons
    const prevBtn = wrap.querySelector(".prev");
    const nextBtn = wrap.querySelector(".next");
    prevBtn.addEventListener("click", this.emblaInstance.scrollPrev, false);
    nextBtn.addEventListener("click", this.emblaInstance.scrollNext, false);
  },
  beforeDestroy() {
    this.emblaInstance.destroy();
  },
};
</script>

<style lang="scss" scoped>
.embla {
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.embla-viewport {
  overflow: hidden;
  width: 100%;

  &.is-draggable {
    cursor: move;
    cursor: grab;
  }

  &.is-dragging {
    cursor: grabbing;
  }
}

.embla-container {
  display: flex;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -webkit-tap-highlight-color: transparent;
  margin-left: -10px;
}

.embla-slide {
  position: relative;
  min-width: 100%;
  padding-left: 10px;

  .inner {
    position: relative;
    overflow: hidden;
    height: 80vw;
    max-height: 500px;
  }

  .img {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    height: 80vw;
    max-height: 500px;
    transform: translate(-50%, -50%);
  }
}

.embla-button {
  outline: 0;
  cursor: pointer;
  background-color: transparent;
  touch-action: manipulation;
  position: absolute;
  z-index: 1;
  bottom: -80px;
  transform: translateY(-50%);
  border: 0;
  width: 30px;
  height: 30px;
  justify-content: center;
  align-items: center;
  fill: black;
  padding: 0;

  svg {
    width: 100%;
    height: 100%;
  }

  &:disabled {
    cursor: default;
    opacity: 0.3;
  }

  &.prev {
    left: 30%;
  }

  &.next {
    right: 30%;
  }
}
</style>
